import React from "react"
import { Link, graphql } from "gatsby"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from '../pages/index.module.css'
import * as tagStyles from '../pages/tags.module.css'

class TagPageTemplate extends React.Component {
  render() {
    const props = this.props
    const tag = this.props.pageContext.tag
    const posts = this.props.data.allMarkdownRemark.edges
    const siteTitle = this.props.data.site.siteMetadata.title

    function selectArticle(slug) {
      navigate(slug)
    }

    function combineTags(tagsArticle) {
      let result = ""
      if (tagsArticle) {
        tagsArticle.forEach((element) => {
          let tagArticle = element.replace(/\s/g, '')
          result += ('#' + tagArticle + "  ")
        })

      }
      return result
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={tag.charAt(0).toUpperCase() + tag.slice(1) + ' posts'} />
        {/* <SEO title={tag.toUpperCase()} /> */}
        {/* <header className="tag-page-head">
          <h1 className="page-head-title">#{tag}({props.data.allMarkdownRemark.totalCount})</h1>
        </header> */}
        {/* <div className="post-feed">
            {posts.map(({ node }) => {
              return (
                <PostCard
                  key={node.fields.slug}
                  node={node}
                  postClass={`post`}
                />
              )
            })}
          </div>  */}
        <div className={tagStyles.tagKeyDiv}>
          <p className={tagStyles.tagKey}>#{tag}({props.data.allMarkdownRemark.totalCount})</p>
        </div >

        {
          posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div className={styles.wrapperDiv}>
                <article className={styles.articleDiv} key={node.fields.slug} onClick={() => selectArticle(node.fields.slug)}>
                  <Link className={styles.articleTitle} to={node.fields.slug}>
                    {title}
                  </Link>

                  <p className={styles.articleReleasedDate}>{node.frontmatter.date}</p>

                  <section>
                    <p className={styles.articleContent}
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                    {/* <p className={styles.articleContinueTitle}>Continue...</p> */}

                    <p className={styles.articleTag}>{combineTags(node.frontmatter.tags)}</p>
                  </section>
                </article>
              </div>

            )
          })
        }
      </Layout >
    )
  }
}

export default TagPageTemplate

export const pageQuery = graphql`
  query PostByTag($tag: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$tag] } } }, 
      sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`